.fix-result {
  margin: 0 auto;
  padding: 1em 0 2em;
  max-width: 50em;
  min-width: 300px;
  width: 100%;
}

.fix-result-item {
  background-color: #ffffff;
  color: black;
  display: flex;
  margin: 2px 0 10px;
  padding: 2px 0;
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #B3B3B3;
  transition: 0.1s transform ease-in-out;
  cursor: default;
}

.fix-result-item:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  transform: scale(1.01);
}

.fix-result-item.invalid-item {
  color: #e91e63;
}

.fix-result-col {
  display: flex;
  flex-direction: column;
  padding: 0 0.5em;
  width: calc(100% / 4);
  text-align: left;
  vertical-align: top;
}

.fix-result-col.field-col {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
}

.fix-result-col.raw-col {
  flex-grow: 1;
}

.fix-result-col.desc-col {
  flex-grow: 1;
}

.fix-result-col.val-col {
  flex-grow: 2;
}

.fix-result-label,
.fix-result-value {
  display: inline-block;
  width: 100%;
  padding: 2px 0;
}

.fix-result-label {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: lighter;
}

.fix-result-value {
  font-size: 1.2em;
  line-height: 1.2em;
  word-break: break-all;
}
