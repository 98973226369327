.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

.github-corner-svg {
  fill: #222;
  position: absolute;
  top: 0;
  border: 0;
  right: 0;
}

.corner-triangle {
  fill: white;
}

.octo-arm,
.octo-body {
  fill: #222;
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }

  20%,
  60% {
    transform: rotate(-25deg);
  }

  40%,
  80% {
    transform: rotate(10deg);
  }
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }

  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}
