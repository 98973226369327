.fix-message {
  position: relative;
  text-align: center;
  line-height: 1.875em;
}

.fix-message-label {
  position: absolute;
  left: 50%;
  top: 0;
  font-size: 1rem;
  background-color: white;
  padding: 0 1em;
  transform: translate(-50%, -56%);
}

.fix-message-input {
  height: 80px;
  width: 80%;
  max-width: 48em;
  min-width: 20em;
  margin: auto;
  padding: 1em;
  border: 1px solid #222;
}

.fix-message .DraftEditor-root {
  border: 2px solid #cecece;
  margin: auto;
  max-width: 50em;
  min-width: 300px;
  width: 100%;
}

.fix-message .public-DraftEditorPlaceholder-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #cecece;
  z-index: -1;
  width: 100%;
}

.fix-message .public-DraftEditor-content {
  color: #222;
  padding: 0.5em 1em;
  font-size: 1.25em;
}
